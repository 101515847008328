// src/components/App.js
import React from "react";
// import Header from './Header';
import Section from "./Section";
import Section2 from "./Section2";
import SobreDiego from "./SobreDiego";
import SupportSection from "./SupportSection";
import FormSection from "./FormSection";
import Footer from "./Footer";
import "./blockDevTools";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Section />
      <Section2 />
      <SobreDiego />
      <SupportSection />
      <FormSection />
      <Footer />
    </div>
  );
}

export default App;
