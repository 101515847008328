// SupportSection.js
import React from "react";

const SupportSection = () => {
  return (
    <div className="responsive-container-block outer-container">
      <div className="responsive-container-block inner-container">
        <p className="text-blk section-head-text">Suporte Profissional</p>
        <div className="responsive-container-block">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 team-card-container"
            >
              <div className="team-card">
                <div className="img-wrapper">
                  <img
                    className="team-img"
                    src={member.image}
                    alt={member.name}
                  />
                </div>
                <p className="text-blk name">{member.name}</p>
                <p className="text-blk position">{member.position}</p>
                <div className="social-media-links">
                  {member.socialMedia.map((link, idx) => (
                    <a key={idx} href={link.href}>
                      <img
                        src={link.icon}
                        alt={`${member.name} social media`}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const teamMembers = [
  {
    name: "Adriana Aragão",
    position: "Admin, suporte e apoio",
    image: "images/suporte/adriana.enc",
    socialMedia: [
      {
        href: "https://instagram.com/adrianashow98",
        icon: "https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg",
      },
    ],
  },
  {
    name: "Leticia Honorio",
    position: "Admin, suporte, apoio e TI",
    image: "images/suporte/leticia.enc",
    socialMedia: [
      {
        href: "https://facebook.com/leticia.regina.927",
        icon: "https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-fb.svg",
      },
      {
        href: "https://instagram.com/leticia__honorio",
        icon: "https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg",
      },
    ],
  },
  {
    name: "Mirelle Talissa",
    position: "Admin, suporte e apoio",
    image: "images/suporte/mirelle.enc",
    socialMedia: [
      {
        href: "https://instagram.com/mirelle_talissa",
        icon: "https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg",
      },
    ],
  },
  {
    name: "Thais Souza",
    position: "Admin",
    image: "images/suporte/thais.enc",
    socialMedia: [
      {
        href: "https://x.com/NEGRINHONA",
        icon: "https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-twitter.svg",
      },
      {
        href: "https://instagram.com/negrinhona",
        icon: "https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg",
      },
    ],
  },
];

export default SupportSection;
