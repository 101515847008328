import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./assets/App.css";

const FormSection = () => {
  const [nome, setNome] = useState("");
  const [ipAddress, setIpAddress] = useState("Não disponível");
  const [location, setLocation] = useState({
    city: "",
    region: "",
    country: "",
  });
  const [isLocationConsentGiven, setIsLocationConsentGiven] = useState(false);

  const pageUrl = process.env.REACT_APP_PAGE_URL;
  const redirectedLinkDesktop = process.env.REACT_APP_LINK_REDIRECTED_DESKTOP;
  const redirectedLinkMobile = process.env.REACT_APP_LINK_REDIRECTED_MOBILE;
  const contatoDev = process.env.REACT_APP_LINK_DEV;

  // Função para detectar se o dispositivo é móvel
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const handleLocationConsent = () => {
    setIsLocationConsentGiven(true);
  };

  useEffect(() => {
    if (isLocationConsentGiven) {
      const fetchIpAddress = async () => {
        try {
          const ipResponse = await fetch("https://api.ipify.org?format=json");
          if (ipResponse.ok) {
            const ipData = await ipResponse.json();
            setIpAddress(ipData.ip);
            console.log("IP capturado:", ipData.ip);

            const locationResponse = await fetch(
              `https://ipapi.co/${ipData.ip}/json/`
            );
            if (locationResponse.ok) {
              const locationData = await locationResponse.json();
              setLocation({
                city: locationData.city || "Desconhecido",
                region: locationData.region || "Desconhecido",
                country: locationData.country_name || "Desconhecido",
              });
              console.log("Localização capturada pelo IP:", locationData);
            }
          }
        } catch (error) {
          console.error("Erro ao capturar IP ou localização:", error);
        }
      };

      fetchIpAddress();
    }
  }, [isLocationConsentGiven]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: nome,
      ipAddress,
      city: location.city,
      region: location.region,
      country: location.country,
      link: pageUrl,
      redirectedLinkDesktop,
      redirectedLinkMobile,
      lang: "pt_BR",
    };

    console.log("Dados do formulário:", formData);

    try {
      const response = await fetch("https://formspree.io/f/xqazkedn", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error("Erro ao enviar dados");

      Swal.fire({
        icon: "success",
        title: "Perfeito!",
        text: "Seu formulário foi enviado. Obrigado :)",
      }).then(() => {
        if (isMobileDevice()) {
          window.location.href = redirectedLinkMobile;
        } else {
          window.location.href = redirectedLinkDesktop;
        }
      });
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
      Swal.fire({
        icon: "error",
        title: "Poxa... deu erro!",
        html: `Um problema ao redirecionar. <br/>Por favor, tire um print e informe o erro para o desenvolvedor. Clique em Aviso.`,
        confirmButtonText: "Aviso",
      }).then(() => {
        window.location.href = contatoDev;
      });
    }
  };

  return (
    <section
      className="u-clearfix u-container-align-center u-section-8"
      id="form"
    >
      {!isLocationConsentGiven ? (
        <div className="location-consent-banner">
          <p>
            Este site coleta suas informações para melhorar a experiência. Ao
            continuar, você concorda com o uso dessas informações pessoais?
          </p>
          <button onClick={handleLocationConsent}>Sim, eu concordo</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h1>Formulário</h1>
          <p>
            <label htmlFor="name">Seu nome</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder=""
              required
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </p>

          {/* Informações */}
          <input type="hidden" name="ipAddress" value={ipAddress} />
          <input type="hidden" name="city" value={location.city} />
          <input type="hidden" name="region" value={location.region} />
          <input type="hidden" name="country" value={location.country} />
          <input type="hidden" name="link" value={pageUrl} />
          <input type="hidden" name="desktop" value={redirectedLinkDesktop} />
          <input type="hidden" name="desktop" value={redirectedLinkMobile} />
          <input type="hidden" name="lang" value="pt_BR" />

          <p>
            <button type="submit" className="txtButton">
              Acessar
            </button>
          </p>
        </form>
      )}
    </section>
  );
};

export default FormSection;
